<template>
  <v-container>
    <v-layout>
      <v-row xs12 sm12 md6 lg12 class="pt-5">
        <v-col cols="12" sm="12" md="12" class="contentUsurios">
          <!-- pa-sm-0 mx-md-auto -->
          <v-card class="ma-3 mt-5 pt-5">
            <v-list-item>
              <v-list-item-avatar
                height="80"
                width="80"
                elevation="10"
                tile
                class="mt-n15"
              >
                <v-sheet
                  color="light-blue darken-1"
                  rounded
                  height="80"
                  width="80"
                >
                  <v-icon dark large>system_update_alt</v-icon>
                </v-sheet>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="headline text-left secondary--text mt-n4 fontFuturaMedium"
                >
                  GENERAR REPORTE
                </div>

                <div
                  class="headline text-left secondary--text mt-n1 fontFuturaMedium"
                >
                  <p class="primary--text body-2">
                    Última actualización {{ mes }}
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-row
                  class="flex-row-reverse sm-flex-row align-items-center"
                  xs12
                  sm12
                  md6
                  lg12
                >
                  <v-col class="py-1" cols="12" xs="6" sm="5" md="4">
                    <v-menu offset-y transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mb-3"
                          outlined
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          Descargar como
                          <v-icon class="ml-3" right dark>
                            mdi-cloud-download
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list class="p-0">
                        <v-list-item @click.prevent="generatePDF">
                          <v-list-item-title>
                            <v-icon class="mr-3">mdi-file-pdf</v-icon>
                            PDF
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col class="py-1" cols="12" xs="6" sm="7" md="8">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-text-search"
                      label="Buscar"
                      single-line
                      hide-details
                      class="pt-0 mr-5 mb-3"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-data-table
              :headers="headers"
              :items="products"
              class="elevation-1"
              :items-per-page="5"
              :loading="cargando"
              :search="search"
              loading-text="Cargando... Por favor espere"
              align="center"
              width="70%"
              @current-items="getFiltered"
            >
              <template v-slot:no-data>
                <v-alert :value="true" color="error" icon="warning">
                  No hay registros por mostrar
                </v-alert>
                <v-btn color="primary" @click="list"> Reset </v-btn>
              </template>

              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

export default {
  data() {
    return {
      search: '',

      // INICIO DESCARGAR PDF
      itemsTable: [],

      // FIN DESCARGAR PDF

      itemsTypeFile: [
        { title: 'PDF', iconFile: 'mdi-file-pdf' },
        { title: 'Excel', iconFile: 'mdi-microsoft-excel' },
      ],

      cargando: true,
      headers: [
        {
          text: 'Laboratorio',
          align: 'start',
          sortable: false,
          value: 'Labs.name',
          class: 'HeadLabs',
        },
        {
          text: 'Producto',
          align: 'start',
          value: 'name',
          sortable: false,
          class: 'HeadProduct',
        },
        {
          text: 'Sustancia',
          align: 'start',
          value: 'Subs.name',
          sortable: false,
        },
        {
          text: 'Forma farmacéutica',
          align: 'start',
          value: 'Forms.name',
          sortable: false,
        },
        {
          text: 'Presentación',
          align: 'start',
          value: 'presentation',
          sortable: false,
          class: 'HeadPresentation',
        },
        {
          text: 'Precio',
          align: 'end',
          value: 'price',
          sortable: false,
          class: 'HeadPrice',
        },
        {
          text: 'CUM',
          align: 'end',
          value: 'cum',
          sortable: false,
        },
      ],

      products: [],
      filteredData: [],

      mes: '',
    };
  },

  created() {
    const fecha = new Date();
    fecha.setMonth(fecha.getMonth() - 1);
    this.mes = fecha.toLocaleDateString('es-ES', {
      month: 'long',
      year: 'numeric',
    });
    this.list();

    this.$gtag.event('clic_seccion_reportes', {
      'event_category': 'Listar Reporte',
      'event_label': 'Ver Reporte',
      'value': 1
    })
  },

  methods: {
    list() {
      this.axios({ url: 'precios/listActivePrices' })
        .then((response) => {
          this.products = response.data;
          this.products.forEach((item) => {
            item['price'] = item['price'].toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          });
          this.cargando = false;
        })
        .catch((error) => {
          this.cargando = false;
          if (error.response.status === 403) {
            localStorage.removeItem('auth');
            localStorage.removeItem('company');
            this.$router.push({ name: 'Login' });
          }
        });
    },

    generatePDF() {
      this.$gtag.event('clic_descargar_reporte', {
        'event_category': 'Descargar reporte',
        'event_label': 'Descargar reporte',
        'value': 1
      })
      
      this.itemsTable = this.products.map((product) => {
        product.Labs_name = product.Labs.name;
        product.Subs_name = product.Subs.name;
        product.Forms_name = product.Forms.name;
        product.price = `${product.price}`;
      });
      const columns = [
        { title: 'LABORATORIO', dataKey: 'Labs_name' },
        { title: 'PRODUCTO', dataKey: 'name' },
        { title: 'SUSTANCIA', dataKey: 'Subs_name' },
        { title: 'FORMA FARMA', dataKey: 'Forms_name' },
        { title: 'PRESENTACIÓN', dataKey: 'presentation' },
        { title: 'PRECIO', dataKey: 'price' },
        { title: 'CUM', dataKey: 'cum' },
      ];
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'cm',
        format: 'letter',
      });

      doc.setTextColor(0, 82, 161);
      doc
        .setFontSize(12)
        .text(
          `| Reporte generado por: ${localStorage.getItem('company')}`,
          12.5,
          1.8
        );
      doc.setFontSize(12).text(`| Fecha: ${this.mes}`, 12.5, 2.4);

      //insertando imagen png
      var logo = require('@/assets/images/logoFPO.png');
      var imgData = new Image();
      imgData.src = logo;
      doc.addImage(imgData, 'PNG', 1.0, 1.2, 11.21, 1.41);

      // Using autoTable plugin
      doc.autoTable({
        columns,
        body: this.filteredData,
        margin: { left: 1.0, top: 3.41 },
      });

      // Using array of sentences
      doc.setFont('helvetica').setFontSize(12);

      // Creating footer and saving file
      doc
        // .setFont("times")
        .setFontSize(9)
        // .setFontStyle("italic")
        .setTextColor(199, 0, 57)
        .setLineWidth(0.01)
        .line(0.5, 1.1, 26.5, 1.0)
        .text(
          'Los precios que se publican en FARMAPRECIOS son un promedio sugerido al público y sirven como medio de guía al droguista y no como precios oficiales de ningún producto,',
          1.0,
          doc.internal.pageSize.height - 0.9
        )
        .text(
          'ni como precios oficiales para ninguna negociación.',
          1.0,
          doc.internal.pageSize.height - 0.5
        )
        .save('Reporte_FPO.pdf');
    },

    getFiltered(e) {
      console.log('data??', e);
      this.filteredData = e;
    },
  },
};
</script>

<style scoped>
.v-sheet.theme--light.v-toolbar.v-toolbar--flat {
  display: none !important;
}

/* // -->  RESPONSIVE  <-- */

/* Mobile Portrait */
@media (max-width: 767px) and (min-width: 300px) {
  v-container {
    padding: 12px !important;
  }
  .ma-3.mt-5.pt-5 {
    margin: 12px !important;
  }
}
</style>
