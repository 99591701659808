<template>
  <v-container>
    <v-layout>
      <v-row xs12 sm12 md6 lg12 class="pt-5">
        <v-col cols="12" sm="12" md="12" lg="12" class="contentUsurios">
          <p class="subtitle-1 text-center red--text text--darken-4 px-6 pb-6">
            Los precios que se publican en <strong>FARMAPRECIOS</strong> son un
            promedio sugerido al público y sirven como medio de guía al
            droguista y no como precios oficiales de ningún producto, ni como
            precios oficiales para ninguna negociación.
          </p>
          <v-card class="ma-3 mt-5 pt-5">
            <v-list-item>
              <v-list-item-avatar
                height="80"
                width="80"
                elevation="10"
                tile
                class="mt-n15"
              >
                <v-sheet
                  color="light-blue darken-1"
                  rounded
                  height="80"
                  width="80"
                >
                  <v-icon dark large>fact_check</v-icon>
                </v-sheet>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="headline text-left secondary--text mt-n4 fontFuturaMedium"
                >
                  PRODUCTOS
                </div>

                <div
                  class="headline text-left secondary--text mt-n1 fontFuturaMedium"
                >
                  <p class="primary--text body-2">
                    Última actualización {{ mes }}
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="products"
              :items-per-page="10"
              :loading="cargando"
              :search="search"
              loading-text="Cargando... Por favor espere"
              align="center"
              width="70%"
            >
              <template v-slot:no-data>
                <v-alert
                  :value="true"
                  outlined
                  text
                  color="error"
                  icon="mdi-information-outline"
                  border="left"
                  class="ma-5"
                >
                  No hay registros por mostrar
                </v-alert>
                <v-btn color="primary" @click="list"> Reset </v-btn>
              </template>

              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>

                  <v-dialog>
                    <template v-slot:activator="{}">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-text-search"
                        label="Buscar"
                        single-line
                        hide-details
                        class="mr-5"
                      ></v-text-field>
                    </template>
                  </v-dialog>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      cargando: true,
      headers: [
        {
          text: 'Laboratorio',
          align: 'start',
          sortable: false,
          value: 'Labs.name',
          class: 'HeadLabs',
        },
        {
          text: 'Nombre comercial',
          align: 'start',
          value: 'name',
          sortable: false,
          class: 'HeadProduct',
        },
        {
          text: 'Sustancia',
          align: 'start',
          value: 'Subs.name',
          sortable: false,
        },
        {
          text: 'Forma farmacéutica',
          align: 'start',
          value: 'Forms.name',
          sortable: false,
        },
        {
          text: 'Presentación',
          align: 'start',
          value: 'presentation',
          sortable: false,
          class: 'HeadPresentation',
        },
        {
          text: 'Precio',
          align: 'end',
          value: 'price_format',
          sortable: false,
          class: 'HeadPrice',
        },
        {
          text: 'CUM',
          align: 'end',
          value: 'cum',
          sortable: false,
        },
      ],

      products: [],
      mes: '',
    };
  },

  created() {
    let fecha = new Date();
    fecha.setMonth(fecha.getMonth() - 1);
    this.mes = fecha.toLocaleDateString('es-ES', {
      month: 'long',
      year: 'numeric',
    });
    this.list();

    this.$gtag.event('clic_seccion_productos', {
      'event_category': 'Listar productos',
      'event_label': 'Ver productos',
      'value': 1
    })
  },

  methods: {
    list() {
      this.axios({ url: 'precios/listActivePrices' })
        .then((response) => {
          this.products = response.data;
          this.products.forEach((item) => {
            item['price_format'] = item['price'].toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          });
          this.cargando = false;
        })
        .catch((error) => {
          this.cargando = false;
          if (error.response.status === 403) {
            localStorage.removeItem('auth');
            localStorage.removeItem('days');
            localStorage.removeItem('company');
            this.$router.push({ name: 'Login' });
          }
        });
    },
  },
};
</script>

<style scoped></style>
