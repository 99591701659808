import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-10YY5XGBV9" }
});

Vue.config.productionTip = false;
window.onunload = () => {
  // Clear the local storage
  window.MyStorage.clear()
}
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
