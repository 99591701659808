<template>
  <div>
    <reports-component></reports-component>
    
  </div>
</template> 

<script>
import ReportsComponent from '../components/ReportsComponent.vue'
export default {
  components: {
    ReportsComponent,
  }
}
</script>
