'use strict';

import Vue from 'vue';
import axios from 'axios';
// import VueAxios from 'vue-axios';

axios.defaults.withCredentials = true;
// Vue.use(VueAxios, axios);

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: 'https://admin.farmapreciosonline.com/api/',
  // baseURL: 'https://golondriweb.herokuapp.com/api',
  // baseURL: 'https://localhost:3030/api',
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  headers: {
    'Access-Control-Allow-Origin': 'https://admin.farmapreciosonline.com/',
    // 'Access-Control-Allow-Origin': 'https://golondriweb.herokuapp.com/',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true,
    credentials: 'include',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    'Access-Control-Allow-Headers':
      'Authorization, X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Allow-Request-Method',
    Allow: 'GET, POST, OPTIONS, PUT, DELETE',
    'X-Requested-With': 'XMLHttpRequest',
    // Authorization: `Bearer ${connect.sid}`
  },
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
