<template>
    <v-container>
    <v-layout>
      <v-row xs12 sm12 md6 lg12 class="pt-10 pt-sm-10 pt-md-5">
        <v-col cols="12" sm="12" md="12" lg="12" class="contentUsurios">
          <v-card class="ma-3 mt-5 pt-5">
            <v-list-item>
              <v-list-item-avatar
                height="80"
                width="80"
                elevation="10"
                tile
                class="mt-n15"
              >
                <v-sheet
                  color="cyan darken-1"
                  rounded
                  height="80"
                  width="80"
                >
                  <v-icon dark large>auto_stories</v-icon>
                </v-sheet>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="headline text-left secondary--text mt-n4 fontFuturaMedium"
                >
                  CARTA DEL DROGUISTA
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-container>
              <v-layout>
                <v-row class="justify-center py-10">
                  <v-col cols="12" sm="12" md="6" lg="4">
                    <v-card
                      class="mx-auto"
                      max-width="300"
                    >
                      <v-img
                        height="200px"
                        src="@/assets/images/imgTemaActual.jpg"
                      >
                      </v-img>

                      <v-card-text class="primary--text">
                        <div class="pb-0 caption">
                          TEMA CENTRAL
                        </div>
                        <div class="subtitle-1">
                          <strong>ENFERMEDADES LABORALES</strong>
                        </div>
                        <div class="pb-0">
                          Edición Julio - 2021
                        </div>
                      </v-card-text>


                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <a href="https://farmapreciosonline.com/docs/carta_actual.pdf" target="_blank" rel="noopener noopener" @click="publicacionActual">
                          <v-btn color="success" class="ma-2">
                            Ver publicación
                          </v-btn>
                        </a>

                      </v-card-actions>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="12" md="6" lg="4">
                    <v-card
                      class="mx-auto"
                      max-width="300"
                    >
                      <v-img
                        height="200px"
                        src="@/assets/images/imgTemaAnterior.jpg"
                      >
                      </v-img>

                      <v-card-text class="primary--text">
                        <div class="pb-0 caption">
                          TEMA CENTRAL
                        </div>
                        <div class="subtitle-1">
                          <strong>NEUROLOGÍA - PSIQUIATRÍA</strong>
                        </div>
                        <div class="pb-0">
                          Edición JUNIO - 2021
                        </div>
                      </v-card-text>


                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <a href="https://farmapreciosonline.com/docs/carta_anterior.pdf" target="_blank" rel="noopener noopener"  @click="publicacionAterior">
                          <v-btn color="success" class="ma-2">
                            Ver publicación
                          </v-btn>
                        </a>

                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-layout>
            </v-container>

          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  created() {
    this.$gtag.event('clic_seccion_carta', {
      'event_category': 'Listar Carta del Droguista',
      'event_label': 'Ver Carta del Droguista',
      'value': 1
    })
  },
  
 methods: {
   publicacionActual() {
      this.$gtag.event('clic_carta_publicacion_actual', {
        'event_category': 'Ver publicación actual',
        'event_label': 'Publicación actual',
        'value': 1
      })
    },

   publicacionAterior() {
      this.$gtag.event('clic_carta_publicacion_anterior', {
        'event_category': 'Ver publicación anterior',
        'event_label': 'Publicación anterior',
        'value': 1
      })
    },
 }
}
</script>

<style>

</style>