import Vue from 'vue';
import Login from '../views/Login.vue';
import VueRouter from 'vue-router';
import Products from '../views/Products.vue';
import Reports from '../views/Reports.vue';
import CartaDroguista from '../views/CartaDroguista.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/carta_droguista',
    component: CartaDroguista,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reports',
    component: Reports,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!(localStorage.getItem('auth') ?? false) && to.name !== 'Login') {
    next('/');
  } else if (localStorage.getItem('auth') === 'true' && to.name === 'Login') {
    next({ name: 'Products' });
  } else {
    next();
  }
});

export default router;
