<template>
  <div>
    <carta-droguista></carta-droguista>
  </div>
</template> 

<script>
import CartaDroguista from '../components/CartaComponent.vue'

export default {
  components: {
    CartaDroguista,
  }
}
</script>