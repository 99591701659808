<template>
  <v-app class="myFont">
    <!-- :mini-variant.sync="mini" -->
    <v-navigation-drawer
      app
      id="default-drawer"
      v-if="this.$router.history.current.fullPath != '/'"
      v-model="drawer"
      src="@/assets/images/bckgrdSideBar.jpg"
      mini-variant-width="80"
      width="260"
      dark
      class="dark darken-4"
    >
      <v-list flat>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h2>Menú</h2>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mx-3"></v-divider>


        <v-list-item
          v-for="link in links"
          :key="link.text"
          router
          :to="link.route"
          class="ma-4"
          active-class="activeUsuarios"
        >
          <v-list-item-action class="mr-4">
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          href="https://www.youtube.com/watch?v=lBYIc9O4HcU" target="_blank"
          class="ma-4"
          active-class="activeUsuarios"
          @click="instructions"
        >
          <v-list-item-action class="mr-4">
            <v-icon>checklist</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Instrucciones de uso</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <template>
          <v-dialog v-model="dialog" width="600px" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                class="ma-4 pr-0"
                active-class="activeUsuarios"
              >
                <v-list-item-action class="mr-4">
                  <v-icon>mdi-playlist-check</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Términos y condiciones</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <terms-component v-on:close-dialog="close"></terms-component>
          </v-dialog>
        </template>

        <v-list-item class="ma-4" active-class="activeUsuarios">
          <v-list-item-action class="mr-4">
            <v-icon></v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      v-if="this.$router.history.current.fullPath != '/'"
      color="light"
    >
      <v-app-bar-nav-icon
        class="hidden-xs-and-up"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <img class="logoHeader" src="@/assets/images/logoFPheader.svg" alt="" />

      <v-spacer></v-spacer>

      <v-menu offset-y transition="slide-y-transition" min-width="180px">
        <template v-slot:activator="{ on }">
          <v-btn class="ml-3" icon large v-on="on">
            <v-icon color="blue darken-4">mdi-account-arrow-right</v-icon>
          </v-btn>
        </template>
        <v-list flat class="text-start">
          <v-list-item v-if="days > 10">
            <v-list-item-title class="primary--text mr-2">
              <v-icon color="primary " class="mr-3">mdi-chat-alert</v-icon>
              Su código expira en
              <strong color="primary">{{ getDays }} días</strong>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="days <= 10">
            <v-list-item-title class="red--text text--darken-4 mr-2">
              <v-icon color="red darken-4" class="mr-3">mdi-chat-alert</v-icon>
              Su código expira en
              <strong color="red darken-4">{{ getDays }} días</strong>
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click.prevent="logOut">
            <v-list-item-title class="primary--text">
              <v-icon color="primary" class="mr-3">mdi-power</v-icon>
              Salir
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-footer
      inset
      app
      v-if="this.$router.history.current.fullPath != '/'"
      color="light"
      padless
      fixed
      class="footerClient elevation-6"
    >
      <v-row justify="center" no-gutters>
        <v-col
          class="white py-1 text-center text-sm-center text-md-left primary--text"
          cols="12"
          sm="6"
          md="6"
          lg="6"
        >
          <p class="subtitle-2 mb-0 ml-3">
            <strong>PLM Colombia S.A.S.</strong> <br />
            Calle 103 No. 64-50 - Bogotá, Colombia
          </p>
        </v-col>

        <v-col
          class="white py-1 text-center text-sm-center text-md-right primary--text"
          cols="12"
          sm="6"
          md="6"
          lg="6"
        >
          <p class="subtitle-2 mb-0 mr-3">
            <strong
              >PBX:
              <a
                href="tel:031533 5022"
                target="_blank"
                title="tel:031533 5022"
                rel="noopener"
                >(571)533 5022</a
              ></strong
            >
            <br />

            <a
              href="mailto:carolina.diaz@plmlatina.com"
              target="_blank"
              title="carolina.diaz@plmlatina.com"
              rel="noopener"
              >carolina.diaz@plmlatina.com</a
            >
          </p>
        </v-col>
      </v-row>
    </v-footer>

    <v-main>
      <v-container pa-0 fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TermsComponent from './components/TermsComponent.vue';

export default {
  drawer: null,
  components: {
    TermsComponent,
  },
  data() {
    return {
      days: '',
      dialog: false,
      users: [],
      drawer: null,
      links: [
        { icon: 'fact_check', text: 'Productos', route: '/products' },
        {
          icon: 'auto_stories',
          text: 'Carta del droguista',
          route: '/carta_droguista',
        },
        {
          icon: 'system_update_alt',
          text: 'Generar reporte',
          route: '/reports',
        },
      ],
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  computed: {
    getDays() {
      this.days = localStorage.getItem('days');
      return localStorage.getItem('days');
    },
  },

  methods: {
    instructions() {
      this.$gtag.event('clic_ver_instructivo', {
        'event_category': 'Abrir instructivo en Youtube',
        'event_label': 'Ver instructivo',
        'value': 1
      })
    },

    logOut() {
      this.$gtag.event('clic_salir', {
        'event_category': 'Salir de Farmaprecios Online',
        'event_label': 'Salir',
        'value': 1
      })

      this.axios({ url: 'usuario/logout', method: 'post' });
      localStorage.removeItem('auth');
      localStorage.removeItem('days');
      localStorage.removeItem('company');
      this.$router.push('/');
    },

    close() {
      this.dialog = false;
    },
  },

  drawer: {
    mini: false,
  },
};
</script>

<style>
@font-face {
  font-family: 'FuturaStd-Book' !important;
  font-style: normal;
  font-weight: normal;
  src: url('../src/assets/fonts/FuturaStd-Book.ttf');
}

@font-face {
  font-family: 'FuturaStd-Medium' !important;
  font-style: normal;
  font-weight: normal;
  src: url('../src/assets/fonts/FuturaStd-Medium.ttf');
}

#app {
  background-color: #f5f5f5;
}

.swal-modal {
  font-family: 'FuturaStd-Book' !important;
}

.swal-button {
  background-color: #0052a1;
}

.swal-button:not([disabled]):hover {
  background-color: #0d47a1;
}

.myFont {
  font-family: 'FuturaStd-Book';
}

.v-text-field.v-text-field--single-line.v-text-field--is-booted {
  max-width: 300px !important;
}

.logoHeader {
  height: 36px;
}

.activeUsuarios {
  border-left: 4px solid #1565c0;
  background-color: rgba(17, 119, 189, 0.2);
  border-radius: 5px;
}

.footerClient {
  width: auto;
}

a {
  text-decoration: none;
}

/* --- --- CRUD - Usuarios - INICIO --- --- */
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #0051a2 !important;
  font-size: 1em !important;
  font-weight: normal !important;
  font-family: 'FuturaStd-Medium';
}

.v-data-table__mobile-row__header {
  color: #0051a2 !important;
}
/* --- --- CRUD - Usuarios - FIN --- --- */

/* // -->  RESPONSIVE  <-- */
/* Mobile Portrait */
@media (max-width: 767px) and (min-width: 300px) {
  .logoHeader {
    max-height: 20px !important;
  }
  html
    body
    div#app.v-application.myFont.v-application--is-ltr.theme--light
    div.v-application--wrap
    main.v-main
    div.v-main__wrap
    div.container.container--fluid.pa-0
    div
    div.container
    div.layout
    div.row.pt-10
    div.contentUsurios.col-sm-12.col-md-12.col-12
    div.ma-3.mt-5.pt-5.v-card.v-sheet.theme--light
    div.v-data-table.elevation-1.v-data-table--has-top.v-data-table--has-bottom.theme--light
    header.v-sheet.theme--light.v-toolbar.v-toolbar--flat
    div.v-toolbar__content {
    flex-direction: column;
  }

  .headline.text-left.secondary--text {
    font-size: 1.1rem !important;
  }

  .v-data-table-header-mobile__wrapper {
    display: none !important;
  }

  .v-text-field.v-text-field--single-line.v-text-field--is-booted {
    margin-bottom: 1.5rem;
  }

  .v-data-table-header.v-data-table-header-mobile {
    display: none;
  }
  .footerClient {
    margin-left: 0;
    width: 100%;
    position: absolute !important;
    justify-content: center;
  }
}

/* Mobile Portrait */
@media (max-width: 568px) and (min-width: 300px) and (orientation: portrait) {
  v-container {
    padding: 12px !important;
  }
  .ma-3.mt-5.pt-5 {
    margin: 12px !important;
  }
  .contentUsurios {
    padding: 0 !important;
    max-width: 567px !important;
  }
  .v-data-table-header.v-data-table-header-mobile {
    display: none;
  }

  .v-main {
    margin-bottom: 70px;
  }
}

/* iPhone 6/7/8 Landscape */
/* @media (max-width: 667px) and (min-width: 569px) { */
@media (min-width: 667px) and (orientation: landscape) {
  .contentUsurios {
    width: 667px !important;
  }
}

/* iPhone 6/7/8 Plus Landscape */
@media (min-width: 415px) and (orientation: landscape) {
  .contentUsurios {
    width: 640px !important;
  }
}

/* iPhone X/XS Landscape */
@media (max-width: 890px) and (min-width: 769px) {
  .contentUsurios {
    width: 769px;
  }
}

/* iPhone X Landscape */
@media only screen and (min-width: 812px) and (orientation: landscape) {
  .contentUsurios {
    width: 769px;
  }
}

/* iPhone 12 Pro Max Landscape */
@media only screen and (min-width: 926px) and (orientation: landscape) {
  .contentUsurios {
    width: 769px;
  }
}

/* iPhone 12 Pro Max Portrait */
@media only screen and (min-width: 428px) and (orientation: portrait) {
  .contentUsurios {
    width: 428px !important;
  }
}

/* iPad portrait */
@media (min-width: 768px) and (orientation: portrait) {
  .contentUsurios {
    width: 718px;
  }
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) and (orientation: landscape) {
  .contentUsurios {
    width: 708px;
  }
}

/* iPad Pro portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

@media only screen and (min-device-width: 938px) and (max-device-width: 938px) and (orientation: landscape) {
}

@media only screen and (min-device-width: 602px) and (max-device-width: 602px) and (orientation: portrait) {
}

/* Tab's Landscape */
@media (max-width: 1366px) and (min-width: 992px) {
}
</style>
