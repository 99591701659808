<template>
  <v-card style="height: 500px">
    <v-toolbar
      flat
      dark
      color="primary">
      <v-toolbar-title class="text-center">
        <strong>Condiciones de servicio website FARMAPRECIOS ONLINE</strong>
      </v-toolbar-title>

    </v-toolbar>

    <v-card-text>
      <p class="subtitle text-left primary--text mx-5 mt-4">
        <strong>
          Le damos la bienvenida a PLM
        </strong>
      </p>
      <ol>
        <li class="subtitle">
          <strong class="primary--text">Su relación con PLM</strong>

          <ol>
            <li class="body-2 mb-3">
              Al hacer uso de los productos, software, y servicios (en adelante,
              se denominarán los "Servicios") a los que tiene acceso a través de
              la página (en adelante "WEB PLM"), usted acepta regirse por los
              términos y condiciones previstos en el presente documento, en lo
              sucesivo "El acuerdo".
            </li>
            <li class="body-2 mb-3">
              A menos que usted y PLM establezcan lo contrario por escrito, el
              acuerdo siempre incluirá, como mínimo, los términos y condiciones
              estipulados en este documento. En adelante, se denominarán las
              "Condiciones universales".
            </li>
            <li class="body-2 mb-3">
              El acuerdo también incluirá los términos de cualquier aviso legal
              que corresponda a los Servicios, y que de tiempo entiempo se
              publiquen en la página WEB PLM, además de las Condiciones
              universales. En adelante, todo ello se denominará las "Condiciones
              adicionales". Siempre que un Servicio esté regido por las
              Condiciones adicionales, podrá leerlas en el espacio destinado en
              el Servicio o a través de este.
            </li>
            <li class="body-2 mb-3">
              Las Condiciones universales, junto con las Condiciones
              adicionales, conforman El acuerdo legalmente vinculante entre
              usted y PLM, en relación con el uso que usted haga de los
              Servicios. Le recomendamos que las lea detenidamente. En adelante,
              este acuerdo legal se denominará las "Condiciones".
            </li>
            <li class="body-2 mb-3">
              En el caso de que exista alguna incoherencia entre las Condiciones
              adicionales y los Condiciones universales en relación con el
              Servicio, prevalecerán las disposiciones de las primeras.
            </li>
          </ol>
        </li>

        <li class="subtitle">
          <strong class="primary--text">Aceptación de los Condiciones</strong>

          <ol>
            <li class="body-2 mb-3">
              Antes de hacer uso de los Servicios, deberá aceptar primero las
              Condiciones. De lo contrario, no podrá usarlos.
            </li>
            <li class="body-2 mb-3">
              <b
                >Para aceptar las Condiciones, dispone de varias
                posibilidades:</b
              >
              <ol>
                <li class="body-2 mb-3">
                  Hacer clic para aceptar dichas Condiciones, allí donde PLM
                  ofrezca esta opción en la interfaz de usuario del Servicio en
                  cuestión; o bien
                </li>
                <li class="body-2 mb-3">
                  utilizar los Servicios en sí. En ese caso, PLM asumirá que
                  acepta las Condiciones al empezar a usar los Servicios.
                </li>
              </ol>
            </li>
            <li class="body-2 mb-3">
              No podrá utilizar los Servicios ni aceptar las Condiciones si (a)
              no tiene la edad legal para contraer un acuerdo vinculante con
              PLM, (b) si se le ha privado el uso y disfrute de los Servicios en
              virtud de la legislación de la República de Colombia o de
              cualquier otro país, incluido su país de residencia o el país
              desde el cual utiliza los Servicios.
            </li>
            <li class="body-2 mb-3">
              Antes de continuar, le recomendamos imprimir las Condiciones
              universales o guardar una copia de estas en la unidad de disco
              local para su información.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">Idioma de las Condiciones</strong>

          <ol>
            <li class="body-2 mb-3">
              Latraducción que PLM le proporcione de la versión en español de
              las Condiciones es únicamente a título informativo. La versión en
              español será la que prevalecerá y regirá su relación con PLM.
            </li>
            <li class="body-2 mb-3">
              En elcaso de que exista alguna incoherencia entre la versión en
              español de las Condiciones y la versión traducida, prevalecerá la
              versión en español.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text"
            >Provisión de los Servicios por parte de PLM</strong
          >

          <ol>
            <li class="body-2 mb-3">
              PLM cuenta con entidades legales subsidiarias y filiales en todo
              el mundo ("Subsidiarias y filiales"). En ocasiones, estas empresas
              serán las encargadas de proveer los Servicios a nuestros clientes
              a nombre de PLM. Por el presente acuerdo, usted reconoce y acepta
              que dichas Subsidiarias y filiales están autorizadas a proveerle
              dichos Servicios.
            </li>
            <li class="body-2 mb-3">
              PLM está constantemente innovando a fin de ofrecer la mejor
              experiencia posible a sus usuarios. Por el presente acuerdo, usted
              reconoce y acepta que el contenido y la naturaleza de los
              Servicios que proporciona PLM pueden variar ocasionalmente sin
              previo aviso.
            </li>
            <li class="body-2 mb-3">
              Como parte de esta permanente innovación, usted reconoce y acepta
              que PLM pueda suspender, ya sea de forma permanente o temporal,
              los Servicios, o alguna de las funciones incluidas en estos, para
              usted o para los usuarios en general, a discreción de PLM y sin
              previo aviso. Usted podrá interrumpir el uso que haga de los
              Servicios en cualquier momento que lo desee. No es necesario que
              informe de ello a PLM.
            </li>
            <li class="body-2 mb-3">
              Por el presente acuerdo, usted reconoce y acepta que, si PLM
              inhabilita el acceso a su cuenta, es posible que no pueda acceder
              a los Servicios, a la información de su cuenta o a los archivos u
              otro contenido de esta.
            </li>
            <li class="body-2 mb-3">
              Usted reconoce y acepta que, si bien PLM puede no haber fijado
              ningún límite en cuanto al número de transmisiones para enviar o
              recibir elementos a través de los Servicios ni tampoco en la
              cantidad de espacio de almacenamiento disponible para la provisión
              de cualquiera de los Servicios, PLM se reserva el derecho de
              establecer límites al respecto en cualquier momento y a su
              absoluta discreción.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">Uso de los Servicios</strong>

          <ol>
            <li class="body-2 mb-3">
              A fin de poder acceder a determinados Servicios, es posible que se
              le solicite información personal, por ejemplo, datos
              identificativos e información de contacto, como parte del proceso
              de registro en el Servicio o como parte del uso continuado de los
              ervicios. Usted se compromete a que cualquier información de
              registro que facilite a PLM será cierta, precisa, correcta y
              actual.
            </li>
            <li class="body-2 mb-3">
              Usted acepta utilizar los Servicios exclusivamente con los fines
              estipulados en (a) las Condiciones y (b) cualquier ley o
              regulación aplicable o cualquiera de las prácticas o directrices
              aceptadas en las jurisdicciones pertinentes, incluida cualquier
              ley relativa a la exportación de datos o software a o desde la
              República de Colombia o cualquier país pertinente.
            </li>
            <li class="body-2 mb-3">
              Usted se compromete a no acceder ni a intentar acceder a ninguno
              de los Servicios por ningún otro medio que no sea la interfaz
              provista por PLM, a menos que así se haya establecido en un
              acuerdo por escrito independiente entre usted y PLM. Se compromete
              en concreto a no acceder, o intentar acceder, a ninguno de los
              Servicios utilizando medios automatizados, incluidos secuencias de
              comandos o rastreadores web, y se asegurará de cumplir las
              instrucciones dispuestas en cualquier archivo robots.txt
              correspondiente a los Servicios.
            </li>
            <li class="body-2 mb-3">
              Asimismo, se compromete a no involucrarse en ninguna actividad que
              interfiera o que interrumpa los Servicios o los servidores y redes
              conectados a aquellos.
            </li>
            <li class="body-2 mb-3">
              A menos que se estipule lo contrario en un acuerdo independiente
              entre PLM y usted, usted se compromete a no reproducir, duplicar,
              copiar, vender, comercializar ni revender los Servicios para
              ningún fin. Expresamente se aclara que la reproducción y
              explotación comercial no autorizada de los contenidos del
              aplicativo (s) PLM, constituye una violación a los derechos de
              propiedad industrial e intelectual de PLM y es tipificado como
              delito grave en la legislación mexicana
            </li>
            <li class="body-2 mb-3">
              Por elpresente acuerdo, usted acepta que es el único responsable,
              y que PLM renuncia a toda responsabilidad hacia usted o hacia
              cualquier tercero, del incumplimiento de cualquiera de sus
              obligaciones en virtud de las Condiciones, así como de las
              consecuencias, incluido cualquier pérdida o daño que pueda
              ocasionar a PLM, derivado de dicho incumplimiento.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text"
            >Contraseñas y seguridad de la cuenta</strong
          >

          <ol>
            <li class="body-2 mb-3">
              Por el presente acuerdo, reconoce y acepta que es usted el único
              responsable de mantener la confidencialidad de sus contraseñas
              asociadas a cualquiera de las cuentas que utiliza para acceder a
              los Servicios.
            </li>
            <li class="body-2 mb-3">
              En consecuencia, acepta que usted será el único responsable ante
              PLM de todas y cada una de las actividades que se desarrollen en
              su cuenta.
            </li>
            <li class="body-2 mb-3">
              Acepta notificar de inmediato a PLM cualquier uso no autorizado de
              su contraseña o cuenta de que tenga conocimiento.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text"
            >Información personal y privacidad</strong
          >

          <ol>
            <li class="body-2 mb-3">
              Para obtener información acerca de las prácticas de protección de
              datos que aplica PLM, consulte nuestro "Aviso de Privacidad" en la
              página farmapreciosonline esta política explica el tratamiento que
              hace PLM de su información personal y cómo protege su privacidad
              cuando utiliza los Servicios.
            </li>
            <li class="body-2 mb-3">
              Por su parte, usted acepta el uso de sus datos de acuerdo con las
              políticas de privacidad de PLM.
            </li>
            <li class="body-2 mb-3">
              Los datos personales que recabamos de usted serán utilizados para
              fines de seguimiento, actualización y confirmación en cuanto a
              productos y servicios proporcionados; con fines promocionales y de
              contratación; dar cumplimiento a obligaciones contraídas con
              nuestros clientes; Evaluar la calidad del servicio; realizar
              estudios sobre hábitos de consumo y preferencias.
            </li>
            <li class="body-2 mb-3">
              Por su parte, usted al aceptar los Términos y Condiciones
              presentes acepta que toda vez que la Ley y hasta el marco legal lo
              permita, PLMcomparta sus datos.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">Contenido de los Servicios</strong>

          <ol>
            <li class="body-2 mb-3">
              Por el presente acuerdo, usted reconoce que toda la información,
              como archivos de datos, texto escrito, programas informáticos,
              música, archivos de audio u otros sonidos, fotografías, vídeos u
              otro material gráfico, a la que tenga acceso como parte de los
              Servicios o a través del uso de estos, es responsabilidad
              exclusiva de la persona que ha creado dicho contenido. En
              adelante, dicha información se denominará el "Contenido".
            </li>
            <li class="body-2 mb-3">
              El Contenido ofrecido como parte de los Servicios, incluidos entre
              otros los anuncios que aparecen en los Servicios y el Contenido
              patrocinado, podría estar protegido por derechos de propiedad
              intelectual pertenecientes a los patrocinadores o a los
              anunciantes que facilitan dicho Contenido a PLM, o pertenecientes
              a otras personas o empresas en su nombre. A menos que PLM o los
              propietarios del mencionado Contenido autoricen expresamente lo
              contrario en un acuerdo independiente, usted se compromete a no
              modificar, alquilar, arrendar, prestar, vender ni distribuir este
              Contenido, ni crear trabajos derivados basados en él, ya sea en su
              totalidad o en parte.
            </li>
            <li class="body-2 mb-3">
              Por el presente acuerdo, es consciente de que al utilizar los
              Servicios podría estar expuesto a Contenido que, según su parecer,
              sea ofensivo, indecente o de dudosa reputación y de que, en
              consecuencia, usa los mismos Servicios bajo su propia
              responsabiIidad.
            </li>
            <li class="body-2 mb-3">
              Usted acepta que es el único responsable, y que PLM renuncia a
              toda responsabilidad hacia usted o hacia cualquier tercero, de
              todo el Contenido que cree, transfiera o publique durante el uso
              de los Servicios, así como de las consecuencias derivadas de sus
              acciones, incluido cualquier pérdida o daño que ocasione a PLM.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text"
            >Derechos sobre la propiedad Intelectual</strong
          >

          <ol>
            <li class="body-2 mb-3">
              Por el presente acuerdo, usted acepta que PLM, o sus proveedores
              de licencias, son propietarios de todos los derechos, títulos e
              intereses asociados a los Servicios, incluidos todos los derechos
              sobre la propiedad intelectual que actúen sobre ellos,
              independientemente de si los mencionados derechos están
              registrados o no y de donde existan. Asimismo, reconoce que los
              Servicios pueden contener información considerada confidencial por
              PLM y se compromete a no revelar dicha información sin el
              consentimiento previo por escrito de PLM.
            </li>
            <li class="body-2 mb-3">
              A menos que acuerde lo contrario por escrito con PLM, ninguna de
              las disposiciones de las Condiciones le otorga el derecho a usar
              los nombres y las marcas comerciales de PLM, sus marcas de
              servicio, logotipos, nombres de dominio u otras características
              distintivas de marca.
            </li>
            <li class="body-2 mb-3">
              En caso de que se le conceda el derecho explícito a utilizar
              alguna de estas características de marca a través de un acuerdo
              por escrito e independiente entre PLM y usted, usted se compromete
              a que el uso de dichas características cumpla con el acuerdo en
              cuestión, con cualquiera de las disposiciones aplicables de las
              Condiciones y con las directrices de uso de los elementos de marca
              de PLM, las cuales podrán modificarse periódicamente. Estas
              directrices pueden consultarse en la URL que PLM facilite a tal
              efecto.
            </li>
            <li class="body-2 mb-3">
              Excepto la licencia limitada que se especifica en la Sección 11,
              PLM renuncia a todo derecho, título o interés relativo a cualquier
              Contenido que usted facilite, publique, transfiera o muestre en
              los Servicios o a través de ellos, incluidos los derechos de
              propiedad intelectual que actúen sobre el Contenido,
              independientemente de si los mencionados derechos están
              registrados o no y de donde existen. Salvo que acuerde lo
              contrario por escrito con PLM, usted se compromete a proteger y
              aplicar los mencionados derechos y exime a PLM de la obligación de
              hacerlo en su nombre.
            </li>
            <li class="body-2 mb-3">
              Usted acepta no eliminar, ocultar ni alterar los créditos sobre la
              propiedad intelectual, entre ellos, avisos de derechos de autor y
              de marcas registradas, tanto los incluidos en los Servicios como
              los que se facilitan como parte de estos.
            </li>
            <li class="body-2 mb-3">
              Salvo que <span class="h3">PLM </span>le haya concedido la
              autorización expresamente por escrito, usted se compromete a que,
              durante el uso de los Servicios, no utilizará ninguna marca
              comercial, marca de servicio, nombre comercial o logotipo de
              ninguna empresa u organización en modo alguno que ocasionara
              confusión sobre el propietario o usuario autorizado de dichas
              marcas, nombres o logotipos.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">Licencia de PLM</strong>

          <ol>
            <li class="body-2 mb-3">
              PLM le concede una licencia personal, internacional, no exclusiva,
              gratuita e intransferible, para utilizar el software proporcionado
              por PLM como parte de los Servicios (en adelante, el "Software").
              Esta licencia se otorga con el único propósito de permitirle
              utilizar los Servicios que le ofrece PLM y beneficiarse de ellos
              según lo estipulado en las Condiciones.
            </li>
            <li class="body-2 mb-3">
              Queda prohibido copiar, modificar, descompilar o intentar extraer
              el código fuente de la WEB PLM total o parcialmente, o crear
              trabajos derivados o realizar ingeniería inversa de este, así como
              permitir que un tercero lo haga, a menos que así lo permita o
              exija la ley, o a menos que PLM le conceda tales derechos por
              escrito.
            </li>
            <li class="body-2 mb-3">
              A menos que PLM le haya concedido el permiso por escrito, no podrá
              ceder ni conceder sublicencias de sus derechos para utilizar los
              Servicios de la WEB PLM, conceder intereses sobre la seguridad en
              cuanto a estos derechos ni transferir ninguno de tales derechos.
            </li>
            <li class="body-2 mb-3">
              La reproducción y explotación comercial no autorizada de la
              programación y software proporcionado por PLM, constituye un
              delito grave de acuerdo a la legislación mexicana.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">Su licencia del Contenido</strong>

          <ol>
            <li class="body-2 mb-3">
              Usted conservará los derechos de autor y cualquier otro derecho
              que ya posea del Contenido que envíe, publique o muestre en los
              Servicios o a través de ellos. Al enviar, publicar o mostrar
              Contenido, estará concediendo a PLM una licencia permanente,
              internacional, irrevocable, no exclusiva y gratuita para
              reproducir, adaptar, modificar, traducir, publicar, representar y
              mostrar públicamente, así como para distribuir, cualquier
              Contenido que envíe, publique o muestre en los Servicios o a
              través de ellos. Esta licencia se otorga con el único propósito de
              permitir a PLM publicar, distribuir y promocionar los Servicios y
              puede revocarse para determinados Servicios, según lo estipulado
              en las Condiciones adicionales asociadas.
            </li>
            <li class="body-2 mb-3">
              Usted acepta que esta licencia concede a PLM el derecho de
              distribuir el Contenido a otras empresas, organizaciones o
              personas con las que PLM mantiene relaciones con el fin de ofrecer
              servicios sindicados y de utilizar dicho Contenido en relación con
              la provisión de dichos servicios.
            </li>
            <li class="body-2 mb-3">
              Por otra parte, usted es consciente de que, al llevar a cabo los
              pasos técnicos requeridos para ofrecer los Servicios a sus
              usuarios, PLM podrá (a) transmitir o distribuir el Contenido a
              través de diversas redes públicas y distintos medios; y (b)
              aplicar los cambios necesarios al Contenido a fin de adaptarlo a
              los requisitos técnicos que exigen las redes, los dispositivos,
              los servicios o los medios de conexión pertinentes. Usted acepta
              que, por la presente licencia, se le concede a PLM el derecho de
              llevar a cabo estas acciones.
            </li>
            <li class="body-2 mb-3">
              Usted confirma y garantiza a PLM que posee todos los derechos,
              poderes y autoridad necesarios para conceder la licencia
              anteriormente mencionada.
            </li>
            <li class="body-2 mb-3">
              PLM se obliga a no ocultar, sustituir o eliminar el crédito que en
              su calidad de autor del Contenido usted incluya.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">Actualizaciones de software</strong>

          <ol>
            <li class="body-2 mb-3">
              El Software que utiliza podrá descargar e instalar automáticamente
              actualizaciones de PLM. Dichas actualizaciones están diseñadas
              para mejorar, optimizar y desarrollar los Servicios y pueden
              presentarse en forma de correcciones, funciones mejoradas, nuevos
              módulos de software y versiones totalmente nuevas. Usted acepta
              recibir dichas actualizaciones, y permite a PLM proporcionárselas,
              como parte de estos Servicios.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text"
            >Finalización de su relación con PLM</strong
          >

          <ol>
            <li class="body-2 mb-3">
              Las Condiciones seguirán vigentes hasta que PLM o usted decidan
              terminarlas, según lo estipulado a continuación.
            </li>
            <li class="body-2 mb-3">
              Para terminar el acuerdo legal establecido entre PLM y usted,
              deberá (a) notificárselo a PLM en cualquier momento y (b) cerrar
              las cuentas de todos los Servicios de los que hace uso, allí donde
              PLM le ofrezca la opción. La notificación deberá enviarse por
              escrito a la dirección de PLM que se indica al principio de estas
              Condiciones.
            </li>
            <li class="body-2 mb-3">
              PLM podrá terminar el acuerdo legal establecido con usted en
              cualquier momento si:
              <ol>
                <li class="body-2 mb-3">
                  Ha incumplido alguna de las disposiciones de las Condiciones o
                  ha actuado de algún modo que demuestre que no tiene intención
                  de cumplir tales disposiciones o que no puede cumplirlas.
                </li>
                <li class="body-2 mb-3">
                  Así se lo exige a PLM la ley, por ejemplo, en caso de que la
                  provisión de los Servicios sea ilegal.
                </li>
                <li class="body-2 mb-3">
                  El socio con el que PLM le ofrece los Servicios ha suspendido
                  su relación con PLM o ha dejado de proporcionarle a usted los
                  Servicios.
                </li>
                <li class="body-2 mb-3">
                  PLM se encuentra en trámites de interrumpir la provisión de
                  los Servicios a los usuarios del país de residencia del
                  usuario o en el que este utiliza el Servicio.
                </li>
                <li class="body-2 mb-3">
                  La provisión de los Servicios por parte de PLM ha dejado de
                  ser comercialmente viable desde el punto de vista de PLM.
                </li>
              </ol>
            </li>
            <li class="body-2 mb-3">
              Ninguna de las disposiciones expuestas en esta Sección afectará a
              los derechos de PLM en cuanto a la provisión de los Servicios en
              virtud de la Sección 4 de las Condiciones.
            </li>
            <li class="body-2 mb-3">
              Cuando estas Condiciones se rescindan, todos los derechos, las
              obligaciones y las responsabilidades legales por las que PLM y
              usted se hayan regido, hayan acumulado durante la vigencia de las
              Condiciones o que continúen vigentes indefinidamente quedarán
              inalterados, y las disposiciones del párrafo 20.7 seguirán
              aplicándose a tales derechos, obligaciones y responsabilidades
              indefinidamente.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">EXCLUSIÓN DE GARANTÍAS</strong>

          <ol>
            <li class="body-2 mb-3">
              NINGUNA DE LAS DISPOSICIONES DE ESTAS CONDICIONES, INCLUIDAS LAS
              SECCIONES 14 Y 15, CONSTITUIRÁ LA EXCLUSIÓN O LIMITACIÓN DE LAS
              RESPONSABILIDADES DE PLM POR PÉRDIDAS QUE NO ESTÉN EXCLUIDAS O
              LIMITADAS POR LA LEGISLACIÓN VIGENTE. ALGUNAS JURISDICCIONES NO
              PERMITEN LA EXCLUSIÓN DE CIERTAS GARANTÍAS O CONDICIONES NI LA
              LIMITACIÓN O EXCLUSIÓN DE RESPONSABILIDADES POR PÉRDIDAS O DAÑOS
              ORIGINADOS POR NEGLIGENCIA, INCUMPLIMIENTO DE CONTRATO O
              INCUMPLIMIENTO DE LAS CONDICIONES IMPLÍCITAS, O BIEN POR DAÑOS
              FORTUITOS O DERIVADOS. DE ACUERDO CON ELLO, SÓLO LAS LIMITACIONES
              QUE SEAN LEGALES EN SU JURISDICCIÓN SE APLICARÁN EN SU CASO, Y
              NUESTRA RESPONSABILIDAD SE LIMITARÁ AL MÁXIMO PERMITIDO POR LA
              LEY.
            </li>
            <li class="body-2 mb-3">
              USTED MANIFIESTA Y ADMITE EXPRESAMENTE QUE EL USO DEL SERVICIO SE
              REALIZA BAJO SU PROPIA RESPONSABILIDAD Y QUE EL SERVICIO SE
              SUMINISTRA "TAL CUAL" Y EN FUNCIÓN DE SU "DISPONIBILIDAD".
            </li>
            <li class="body-2 mb-3">
              En concreto, PLM, sus subsidiarias y filiales, y sus proveedores
              de licencias no garantizan que:
              <ol>
                <li class="body-2 mb-3">
                  EL USO QUE USTED HAGA DE LOS SERVICIOS SE AJUSTE A SUS
                  NECESIDADES ESPECÍFICAS.
                </li>
                <li class="body-2 mb-3">
                  LOS SERVICIOS SE PROPORCIONEN DE FORMA ININTERRUMPIDA,
                  PRECISA, SEGURA O SIN ERRORES.
                </li>
                <li class="body-2 mb-3">
                  LA INFORMACIÓN QUE OBTENGA COMO CONSECUENCIA DEL USO DE LOS
                  SERVICIOS SEA EXACTA O FIABLE.
                </li>
                <li class="body-2 mb-3">
                  SE CORREGIRÁN LOS DEFECTOS DE FUNCIONAMIENTO Y OPERATIVIDAD DE
                  CUALQUIER SOFTWARE QUE SE PROPORCIONE COMO PARTE DE LOS
                  SERVICIOS.
                </li>
              </ol>
            </li>
            <li class="body-2 mb-3">
              TODO MATERIAL DESCARGADO U OBTENIDO POR CUALQUIER OTRO MEDIO A
              TRAVÉS DEL USO DE LOS SERVICIOS CORRE A SU CUENTA Y RIESGO, Y
              USTED SERÁ EL ÚNICO RESPONSABLE DE CUALQUIER DAÑO OCASIONADO EN SU
              SISTEMA INFORMÁTICO O EN CUALQUIER OTRO DISPOSITIVO, ASÍ COMO DE
              LA PÉRDIDA DE DATOS, QUE PUEDA RESULTAR DE LA DESCARGA DE DICHO
              MATERIAL.
            </li>
            <li class="body-2 mb-3">
              NINGÚN TIPO DE NOTIFICACIÓN NI INFORMACIÓN, YA SEA ORAL O ESCRITA,
              OBTENIDA POR USTED DE PLM O DE LOS SERVICIOS, O MEDIANTE ESTOS
              ÚLTIMOS, ORIGINARÁ GARANTÍAS NO MENCIONADAS EXPRESAMENTE EN LAS
              PRESENTES CONDICIONES.
            </li>
            <li class="body-2 mb-3">
              PLM RENUNCIA EXPRESAMENTE A TODAS LAS GARANTÍAS Y CONDICIONES DE
              CUALQUIER TIPO, YA SEAN EXPLÍCITAS O IMPLÍCITAS, INCLUYENDO ENTRE
              OTRAS LAS GARANTÍAS Y CONDICIONES IMPLÍCITAS DE COMERCIABILIDAD,
              APTITUD PARA UN FIN CONCRETO Y NO INFRACCIÓN.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">LIMITACIÓN DE RESPONSABILIDADES</strong>

          <ol>
            <li class="body-2 mb-3">
              EN VIRTUD DE LAS DISPOSICIONES DEL PÁRRAFO 14.1, PLM, SUS
              SUBSIDIARIAS Y FILIALES Y SUS PROVEEDORES DE LICENCIAS NO SERÁN
              RESPONSABLES ANTE USTED POR:
              <ol>
                <li class="body-2 mb-3">
                  NINGÚN DAÑO DIRECTO, INDIRECTO, FORTUITO, ESPECIAL, DERIVADO
                  NI EJEMPLAR EN QUE USTED HAYA INCURRIDO, SEA CUAL SEA SU CAUSA
                  Y BAJO NINGUNA TEORÍA DE RESPONSABILIDAD. ELLO INCLUYE, ENTRE
                  OTRAS, CUALQUIER PÉRDIDA DE BENEFICIOS, YA SEA DIRECTA O
                  INDIRECTA, CUALQUIER PÉRDIDA DE PRESTIGIO PROFESIONAL O
                  REPUTACIÓN EMPRESARIAL, CUALQUIER PÉRDIDA DE DATOS POR SU
                  PARTE, GASTOS DEL ABASTECIMIENTO DE BIENES O SERVICIOS
                  SUSTITUTIVOS U OTRAS PÉRDIDAS INTANGIBLES.
                </li>
                <li class="body-2 mb-3">
                  NINGUNA PÉRDIDA O DAÑO EN QUE USTED PUEDA INCURRIR, INCLUIDOS
                  ENTRE OTROS PÉRDIDAS O DAÑOS ORIGINADOS COMO CONSECUENCIA DE:
                  <ol>
                    <li class="body-2 mb-3">
                      LA CONFIANZA DEPOSITADA POR SU PARTE EN LA EXHAUSTIVIDAD,
                      PRECISIÓN O EXISTENCIA DE PUBLICIDAD O COMO CONSECUENCIA
                      DE CUALQUIER RELACIÓN O TRANSACCIÓN ENTRE USTED Y OTRO
                      ANUNCIANTE O PATROCINADOR CUYA PUBLICIDAD APAREZCA EN LOS
                      SERVICIOS;
                    </li>
                    <li class="body-2 mb-3">
                      LOS CAMBIOS QUE PLM PUEDA INCORPORAR EN LOS SERVICIOS O LA
                      INTERRUPCIÓN TEMPORAL O DEFINITIVA DE ESTOS O DE ALGUNA DE
                      SUS FUNCIONES;
                    </li>
                    <li class="body-2 mb-3">
                      LA ELIMINACIÓN O DAÑO DEL CONTENIDO Y DE OTROS DATOS DE
                      DIVULGACIÓN QUE SE ALOJAN EN LOS SERVICIOS O SE OFRECEN A
                      TRAVÉS DE ESTOS, ASÍ COMO LA IMPOSIBILIDAD DE
                      ALMACENARLOS;
                    </li>
                    <li class="body-2 mb-3">
                      LA IMPOSIBILIDAD DE PROPORCIONAR A PLM INFORMACIÓN PRECISA
                      DE LA CUENTA;
                    </li>
                    <li class="body-2 mb-3">
                      LA NEGLIGENCIA POR NO HABER PRESERVADO SU CONTRASEÑA O LA
                      INFORMACIÓN DE SU CUENTA DE FORMA SEGURA Y CONFIDENCIAL.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li class="body-2 mb-3">
              LAS LIMITACIONES DE LA RESPONSABILIDAD DE PLM ANTE USTED
              ESTIPULADAS EN EL PÁRRAFO 15.1 SE APLICARÁN INDEPENDIENTEMENTE DE
              SI SE LE HAN NOTIFICADO TALES POSIBILIDADES DE PÉRDIDAS A PLM O DE
              SI PLM DEBERÍA HABER SIDO CONSCIENTE DE DICHA POSIBILIDAD.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong
            >Políticas relativas a los derechos de autor y marcas
            comerciales</strong
          >

          <ol>
            <li class="body-2 mb-3">
              La política de PLM consiste en responder a las notificaciones de
              supuestas infracciones de derechos de propiedad intelectual
              (marcas y/o derechos de autor) en los contenidos generados por
              usted u otros licenciantes de contenidos, de acuerdo con lo
              estipulado por la Ley Federal del Derecho de Autor. PLM se reserva
              el derecho de eliminar de la página de Internet, el contenido
              objeto de la reclamación. Asimismo podrá cancelar las cuentas de
              usuarios que violen derechos de propiedad intelectual propiedad de
              terceros.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">Publicidad</strong>

          <ol>
            <li class="body-2 mb-3">
              Algunos de los Servicios se financian a través de los ingresos
              obtenidos de la publicidad y pueden mostrar anuncios y
              promociones. Este tipo de publicidad podrá estar orientada al
              contenido de la información almacenada en los Servicios, a las
              consultas realizadas a través de estos o a otro tipo de
              información.
            </li>
            <li class="body-2 mb-3">
              La manera, el modo y el alcance de dicha publicidad de PLM en los
              Servicios están sujetos a cambios sin previo aviso.
            </li>
            <li class="body-2 mb-3">
              Como contraprestación del acceso y el uso de los Servicios, usted
              acepta que PLM muestre dicha publicidad en los Servicios.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">Otros contenidos</strong>

          <ol>
            <li class="body-2 mb-3">
              Los Servicios podrán incluir hipervínculos a otros sitios,
              contenido o recursos de Internet. PLM no ejerce ningún control
              sobre los recursos y sitios web que proporcionen terceros ajenos a
              PLM.
            </li>
            <li class="body-2 mb-3">
              Usted reconoce y acepta que PLM no se responsabiliza de la
              disponibilidad de dichos sitios web o recursos externos y que no
              respalda la publicidad, productos u otros materiales
              proporcionados en los sitios web o recursos en cuestión o a través
              de ellos, ni es responsable de ellos.
            </li>
            <li class="body-2 mb-3">
              Usted reconoce y acepta que PLM no se responsabiliza de ninguna de
              las pérdidas o daños en que usted pueda incurrir como consecuencia
              de la disponibilidad de dichos sitios web o recursos externos o
              como consecuencia de la confianza que usted deposite en la
              exhaustividad, precisión o existencia de publicidad, productos u
              otros materiales proporcionados en los sitios web o recursos en
              cuestión o a través de ellos.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">Cambios en las Condiciones</strong>

          <ol>
            <li class="body-2 mb-3">
              PLM podrá incorporar cambios en las Condiciones universales o
              Condiciones adicionales según considere oportuno. En ese caso, PLM
              incluirá la nueva versión de las Condiciones universales y
              proporcionará las Condiciones adicionales en los Servicios
              correspondientes o a través de estos.
            </li>
            <li class="body-2 mb-3">
              Usted reconoce y acepta que si utiliza los Servicios después de
              haberse incorporado los cambios pertinentes en las Condiciones
              universales y Condiciones adicionales PLM asumirá que los acepta.
            </li>
          </ol>
        </li>
        <li class="subtitle">
          <strong class="primary--text">Condiciones legales generales</strong>

          <ol>
            <li class="body-2 mb-3">
              En ocasiones, es posible que, como consecuencia del uso de los
              Servicios o de acciones realizadas a través estos, utilice un
              servicio, descargue un programa o adquiera productos
              proporcionados por otra persona o empresa. El uso que haga de
              todos ellos estará sujeto a otros términos establecidos entre la
              persona o empresa en cuestión y usted. En ese caso, las
              Condiciones no afectarán a la relación legal que establezca con
              estas otras personas o empresas.
            </li>
            <li class="body-2 mb-3">
              Las presentes Condiciones constituyen el acuerdo legal completo
              entre PLM y usted, rigen el uso que usted haga de los Servicios,
              sin incluir los servicios que PLM le ofrezca en virtud de un
              acuerdo por escrito independiente, y sustituyen cualquier acuerdo
              previo entre PLM y usted relativo a los Servicios.
            </li>
            <li class="body-2 mb-3">
              Usted acepta que PLM puede proporcionarle avisos, incluidos los
              relativos a los cambios de las Condiciones, a través del correo
              electrónico, correo postal o mediante su publicación en los
              Servicios.
            </li>
            <li class="body-2 mb-3">
              Usted acepta que, si PLM no ejerce ninguna acc1on legal o
              reclamación por incumplimiento a lo estipulado en las Condiciones
              o de los que PLM pueda beneficiarse en virtud de alguna ley
              aplicable, se interpretará como que ello no supone una renuncia
              legal de los derechos de PLM y que PLM seguirá disponiendo del
              derecho a ejercer las acciones legales que le beneficien.
            </li>
            <li class="body-2 mb-3">
              Si un tribunal, con la jurisdicción competente para decidir sobre
              este asunto, falla que alguna de las disposiciones de estas
              Condiciones no es válida, dicha disposición se eliminará de las
              Condiciones sin que ello afecte al resto. El resto de las
              Condiciones seguirán siendo válidas y vigentes.
            </li>
            <li class="body-2 mb-3">
              Usted reconoce y acepta que cada uno de los miembros del grupo de
              empresas subsidiarias y filiales de PLM constituyen beneficiarios
              terceros de las Condiciones y que dichas empresas están
              autorizadas a aplicar directamente las disposiciones de las
              Condiciones que les concedan los beneficios o derechos
              pertinentes, así como a basarse en ellas. Excepto lo anteriormente
              estipulado, no habrá ninguna otra persona o empresa beneficiaria
              de las presentes Condiciones.
            </li>
            <li class="body-2 mb-3">
              Las Condiciones de servicio y la relación surgida entre PLM y
              usted por virtud de estas se regirán por las leyes del Estado
              correspondiente, sin contemplarse sus normas respecto a conflicto
              de leyes. PLM y usted aceptan someterse a la jurisdicción
              exclusiva de los tribunales correspondientes para resolver
              cualquier asunto legal derivado de estas Condiciones. Sin
              perjuicio de lo dispuesto, usted acepta que PLM podrá reclamar por
              agravio provisional, o un tipo de agravio legal urgente que sea
              equivalente, en cualquier jurisdicción.
            </li>
          </ol>
        </li>
      </ol>
    </v-card-text>
    <v-card-actions class="elevation-6">
      <v-spacer></v-spacer>
      <v-btn class="ma-1" color="primary" @click="closeModal">
        Entendido
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style scoped>
ol {
  counter-reset: item;
}
li {
  display: block;
}
li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
}
</style>
